import { createApp } from 'vue'
import App from './App.vue'
import router from '@/config/index.js'
import './css/tailwind.css'
import './css/common.css'
import { createHead } from '@vueuse/head'
import { createGtm } from '@gtm-support/vue-gtm'

const app = createApp(App)
const head = createHead()
const GA4_MODE = process.env.GA4_DEBUG_MODE

app.use(router)
app.use(head)
app.use(
  createGtm({
    id: 'GTM-W3RWNMG8',
    debug: GA4_MODE,
  })
)

app.mount('#app')
