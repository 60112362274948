<script setup>
import { onErrorCaptured }  from 'vue';
import { usePostLog } from '@/utils/FacetApi.js'

onErrorCaptured((err,vm, info) => {
  const data = {}
  data.message = err.toString();
  data.vm = "";
  data.info = info;
  usePostLog(data);
  return true;
})
</script>

<template>
  <router-view/>
</template>
