import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect : '/search'
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => 
      import(/* webpackChunkName: "AboutUs" */ '@/views/AboutUs.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () =>
      import(/* webpackChunkName: "SearchCard" */ '@/views/SearchCard.vue')
  },
  {
    path: '/search/list',
    name: 'list',
    component: () =>
      import(/* webpackChunkName: "ListCard" */ '@/views/ListCard.vue'),
    meta: {
      mode: 'searchList',
    }
  },
  {
    path: '/search/detail/:lang/:cardset/:collectorNumber/:name',
    name: 'detail',
    component: () =>
      import(/* webpackChunkName: "DetailCard" */ '@/views/DetailCard.vue'),
    props: route => ({ name: route.params.name , lang: route.params.lang , cardset: route.params.cardset , collectorNumber: route.params.collectorNumber })
  },
  {
    path: '/set/list',
    name: 'setAll',
    component: () =>
      import(/* webpackChunkName: "ListSet" */ '@/views/ListSet.vue')
  },
  {
    path: '/set/:cardset',
    name: 'setList',
    component: () =>
      import(/* webpackChunkName: "ListCard" */ '@/views/ListCard.vue'),
    props: route => ({ cardset: route.params.cardset }),
    meta: {
      mode: 'setList',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 遷移時スクロール制御
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition)
        },50)
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router