import axios from 'axios';

const apiUrl = process.env.VUE_APP_ROOT_API

export function useGetFacet(data, responseExe, cancelSrc) {
    axios.post(apiUrl + 'api/search/facet', 
        data, { 'headers' : { "Content-Type": "application/json" } }
        , { cancelToken : cancelSrc }
    ).then(responseExe);
}

export function usePostLog(data) {
    axios.post(apiUrl + 'api/search/log', 
        data, { 'headers' : { "Content-Type": "application/json" } }
    );
}
